/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    section: "section",
    div: "div",
    p: "p",
    hr: "hr",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, Button} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents), React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-dark uk-light uk-position-z-index  ",
    id: ""
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.p, {
    className: "uk-style-meta uk-color-quinary uk-text-meta-right uk-text-13 uk-scrollspy-inview "
  }, "For buyers and investors"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Quickly and easily qualify projects, highlighting investable opportunities that are adjusted for risk.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Allow the comparison of projects in different parts of the world on a like-for-like basis that are evaluated with consistency, transparency and accuracy.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Facilitate the development and rigorous management of project portfolios that reflect client objectives and dynamic market characteristics.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Mitigate greenwashing risk with defensible carbon credit assessment based on advanced technologies.")), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-quinary uk-text-meta-right uk-text-13 uk-scrollspy-inview "
  }, "For project developers"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Quicker, simpler verification process for land-holders selling nature-based carbon credits – minutes instead of years.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Barriers to entry significantly reduced, including easy access to the best science and significantly reduced costs – from circa $200k to zero for not-for-profits.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Evidence-based assessment of the ‘additionality’, or the true climate impact of each project, including biodiversity, livelihoods and social justice.")), React.createElement("center", null, React.createElement(_components.hr), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-tertiary uk-text-meta-central uk-text-13 uk-scrollspy-inview "
  }, "We give free access to our project evaluation service to NGOs, indigenous and local community-led groups."), React.createElement(_components.hr), React.createElement("br"), React.createElement(_components.h3, {
    className: "uk-style-secondary uk-text-ls-reduce uk-text-5"
  }, "Interested in Canopy PACT's services?"), React.createElement("br"), React.createElement(Button, {
    url: "mailto:eft20@cam.ac.uk",
    style: "primary"
  }, "Get in touch")))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
